<template>
	<div class="box">
		<div class="row-2">
			<div class="col-4">
				<div id="list-example" class="list-group">
					<div class="list-group-item list-group-item-action des" v-for="item in CodeList.CodeList.CodeList" :key="item.id"
							@click="onclick(item.id)">
						第{{ item.id }}题
					</div>
				</div>
			</div>
		</div>
		<div class="code">
			<div class="msg">参考代码如下</div>
			<button @click="visible = true" class="btn btn-secondary allsc">全屏</button>
			<highlightjs :language="CodeList.CodeList.lan" :code="code" class="codetext"/>
		</div>
	</div>
	<s3-layer
	v-model="visible"
	title="全屏代码"
	:skin="layui-layer-lan"
	:content="noe_src"
	:type="2"
	:maxmin="true"
	:area="['100%', '100%']"
	:success="function(layero, index){layer.full(index);}"
	>
	</s3-layer>
</template>


<script setup>
import { defineProps, ref } from "vue"
import $ from 'jquery'
import 'highlight.js/styles/vs2015.css'
// import { layer } from "vue3-layer";
const CodeList = defineProps({
	CodeList: Object,
	m: String,
	lan: String,
});
let code = ref("请选择题目")
let noe_src = ref(location.origin + "/#/bigcode/");
function onclick(id) {
	console.log(id);
	$.ajax({
		type: "GET",
		url: "/"+ CodeList.CodeList.m + "/" + id + ".txt",
		dataType: "text",
		success: function (res) {
			console.log(res);
			code.value = res;
			noe_src = location.origin + "/#/bigcode/" + CodeList.CodeList.lan + "/" + CodeList.CodeList.m + '/' + id;
			console.log(noe_src)
		}
	});
}
let visible = ref(false);
</script>

<style scoped>
	#list-example{
		width: 100px;
		font-size: 14px;
		text-align: center;
	}
	.col-4{
		width: 100px;
		float: left;
		height: 100%;
	}
	.code{
		width: calc(100% - 200px);
		height: 90%;
		margin-top: 10px;
		background-color: #1e1e1e;
		float: left;
		margin-left: 50px;
	}
	.codetext{
		box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
	}

	.box{
		height: 100%;
	}
	.msg{
		color: aliceblue;
		font-size: 20px;
	}
	.des{
		cursor: pointer;
	}
	.allsc{
		position: fixed;
		top: 80px;
		right: 20px;

	}
</style>
<style>
	body{
		width: 100%;
		background-color: #1e1e1e;
	}
</style>
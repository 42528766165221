<template>
    <h1 class="title">二维码生成器</h1>
    <div class="box">
        <img :src="imgsrc" class="qrcode img-thumbnail">
        <br>
        <textarea v-model="input" class="input form-control"></textarea>
        <button @click="gen" type="button" class="btn btn-primary btn1">生成</button>
        <button @click="huan" type="button" class="btn btn-primary btn2">换一个</button>
        <button @click="big" type="button" class="btn btn-primary btn3">放大</button>
        <div class="colors">
            
            <span class="colordes">前景色</span><LayColorPicker v-model="colorq"></LayColorPicker>
            <span class="colordes">背景色</span><LayColorPicker v-model="colorb"></LayColorPicker>
        </div>
        
    </div>
    
</template>

<script setup>
import { ref } from 'vue'; 

let input = ref("")
let imgsrc = ref("")
import QRCode from 'qrcode'
import { LayColorPicker, layer } from '@layui/layui-vue'
let colorq = ref("#000000")
let colorb = ref("#ffffff")
let opts = {
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  quality: 10,
    margin: 1,
    maskPattern: 0,
    width: 1000,
    color: {
        light: "#ffffff",
        dark: "#000000"
    }
}

console.log(opts)
QRCode.toDataURL('hello', opts, function (err, url) {
    console.log(url);
    imgsrc.value = url
})
function gen() {
    opts.color.dark = colorq.value
    opts.color.light = colorb.value
    if (input.value === '') {
        input.value = "请输入文本";
        return;
    }
    console.log(opts)
    QRCode.toDataURL(input.value, opts, function (err, url) {
        imgsrc.value = url;

    })
}
function huan() {
    opts.maskPattern = parseInt(Math.random() * 8) % 8;
    gen();
}
function big() {
    layer.photos({
        imgList: [{ src: imgsrc.value }],
        area: ['100%', '100%'],
    })
}
</script>

<style scoped>
.box{
    margin-top: 100px;
    background: rgb(52, 58, 64);
    margin-left: 15%;
    margin-right: 15%;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}
.qrcode{
    width: 70%;
    max-width: 300px;
}

.form-control{
    max-width: 400px;
    height: 30px;
    text-align: center;
}
.input{
    text-align: left;
    margin-top: 20px;
    margin-left: 50%;
    transform: translate(-50%);
    height: 100px;
}
.btn1{
    margin-top: 20px;
    margin-right: 10px;
    font-size: 12px;
}
.btn2{
    margin-top: 20px;
    font-size: 12px;
    margin-left: 10px;
}
.btn3{
    margin-left: 20px;
    margin-top: 20px;
    font-size: 12px;
}
.title{
    position: relative;
    top: 40px;
    font-weight: 600;
    color: azure;
}
.colors{
    margin-left: 10px;
    position: relative;
    top: 10px;
    background: rgb(52, 58, 64);
    border: 0px;
}
.colordes{
    margin-left: 5px;
    margin-right: 5px;
    color: white;
}
</style>
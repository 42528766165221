<template>
    <div class="out">
        <div class="p404">
        <lay-exception status="404" title="404" describe="跳转页面失败">
            <template #extra>
                <lay-button type="primary" @click="router.push('/')">返回首页</lay-button>
            </template>
        </lay-exception>
        </div>
    </div>
    
</template>
<script setup>
import { LayButton } from '@layui/layui-vue'
import { useRouter } from 'vue-router';
const router = useRouter();
</script>
<style scoped>
.out{
    height: 100%;
    width: 100%;
    background-color: white;
}
.p404 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: white;
}
</style>
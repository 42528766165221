<template>
<div class="out">
    <div class="right">
        <div class="code">
            <highlightjs class="coded" :code="code"/>
        </div>
        
    </div>
    <div class="left">
        <div class="input-group">
        <textarea @on-keydown="textareaTab" placeholder="请输入代码" v-model="code" class="form-control" aria-label="With textarea"></textarea>
    </div>
    </div>
<div class="btnbox">
    <button @click="share" type="button" class="btn  btn-secondary">分享</button><br>
    <button @click="full" type="button" class="btn  btn-secondary">全屏</button><br>
    <button @click="clear" type="button" class="btn  btn-secondary">清空</button>
</div>

</div>
</template>

<script setup>
import { ref } from 'vue';
import { defineEmits, defineProps, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import $ from 'jquery'
import { layer } from "@layui/layui-vue"
let Base64 = require('js-base64').Base64
let code = ref("")
const emit = defineEmits(['sharef'])
const router = useRouter();
const props = defineProps({
    codebase: String
})

if (props.codebase !== '') {
    let str = ref(props.codebase);
    str.value = str.value.replaceAll('_', '/');
    code.value = Base64.decode(str.value)
}
function share() {
    emit('sharef', code.value)
    console.log(code.value)
    if (location.href.match(/https/)) {
        setTimeout(() => {
        navigator.clipboard.writeText(location.href).then(
            () => {
                layer.msg("链接已复制到剪切板")
            },
            () => {
                layer.msg("自动复制失败，请手动复制网址")
            }
        )
    }, 300)
    } else {
        layer.msg("请使用https协议")
    }

}
let noe_src = ref("");
function full() {
    emit('sharef', code.value)
    let str2 = Base64.encode(code.value)
    str2 = str2.replaceAll('/', '_')
    noe_src.value = location.origin + "/#/bigcode/0/0/0/";
    noe_src.value += str2
    console.log(str2, 3);
     layer.open({
        type: "iframe",
        title: "全屏代码",
        area: ['100%','100%'],
        content: noe_src.value
    })
    console.log(noe_src.value)
}
function clear() {
    router.push('/code')
}
function textareaTab (e) {
  if (e.keyCode === 9) {
    if (!this.textareValue) this.textareValue= ''
    this.textareValue+= '\t'
    // 阻止默认切换元素的行为
    if (e && e.preventDefault) {
      e.preventDefault()
    } else {
      window.event.returnValue = false
    }
  }
}
onMounted(() => {

  $("textarea").on(
            'keydown',
            function(e) {
                if (e.keyCode == 9) {
                    e.preventDefault();
                    var indent = '    ';
                    var start = this.selectionStart;
                    var end = this.selectionEnd;
                    var selected = window.getSelection().toString();
                    selected = indent + selected.replace(/\n/g, '\n' + indent);
                    this.value = this.value.substring(0, start) + selected
                            + this.value.substring(end);
                    this.setSelectionRange(start + indent.length, start
                            + selected.length);
                }
            })
})
</script>

<style scoped>
.out{
    height: 90%;
}
.left {
    margin-left: 20px;
    margin-top: 10px;
    height: 30%;
    width: calc(100% - 40px);
}
.input-group{
    height: 100%;
    
}
.right {
    margin-left: 20px;
    height: 65%;
    width: calc(100% - 40px);
    margin-top: 10px;
}
.code {
    text-align: left;
    border-radius: 5px;
    height: 100%;
}

.form-control{
    background-color: #1e1e1e;
    color: white;
    border: gray solid 2px;
}
.coded{
    border: gray solid 2px;
    height: 100%;
    border-radius: 5px;
}
textarea::placeholder{
    color: white;
}
.btnbox{
    position: fixed;
    right: 10px;
    top: 80px;
    
}
.btn {
    margin-top: 5px;
}

</style>
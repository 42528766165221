<template>
	
  <div class="home">
<!-- 	<highlightjs class="hg" language='cpp' :code="code" /> -->
		<CodeSet v-if="sta" :CodeList="{CodeList: json, m: v, lan: lan}"/>
  </div>
</template>

<script setup>
import CodeSet from "./CodeSet.vue"
import $ from "jquery"
import {ref} from 'vue'
let sta = ref(false)
let json = [];
$.ajax({
		url: "shumo.json",
		type: "GET",
		dataType: "json",
		success: function (data) {
      console.log(data)
      sta.value = true;
			json = data;
		}
	})
let v = "shumo"
let lan = "python"
</script>
<style scoped>
.home{
	text-align: left;
}
</style>

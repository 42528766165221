<template>
    <iframe class="ifr" src="/BlackTalk/index.html">

    </iframe>
</template>

<script setup>

</script>

<style scoped>
    .ifr{
        width: 100%;
        height: calc(100% - 56px);
    }
</style>
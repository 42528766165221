<template>
	<NoticeCom />
  <div class="home">
<!-- 	<highlightjs class="hg" language='cpp' :code="code" /> -->
		<CodeSet v-if="sta" :CodeList="{CodeList: json, m: v, lan: lan}"/>
  </div>
  <div class="wea">
	<div id="he-plugin-standard"></div>
  </div>
  
</template>

<script setup>
import CodeSet from "./CodeSet.vue"
import $ from "jquery"
import NoticeCom from "@/components/NoticeCom.vue";
import { ref } from 'vue'
let sta = ref(false)
let json = [];
$.ajax({
		url: "other.json",
		type: "GET",
		dataType: "json",
		success: function (data) {
      console.log(data)
      sta.value = true;
			json = data;
		}
	})
let v = "others"
let lan = "cpp"
</script>
<style scoped>
.home{
	text-align: left;
}	
.wea{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -20%);
}
.s{
	width: 230px;
	height: 270px;
	background-color: aqua;
	color: white;
}
</style>

<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary" v-if="navshou">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">代码展示</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <routerLink :class="other" li to="/">其他代码</routerLink>
            </li>
            <li>
              <routerLink :class="lisan" li to="/lisan">离散上机</routerLink>
            </li>
            <li>
              <routerLink :class="shumo" li to="/shumo">数模</routerLink>
            </li>
            <li>
              <routerLink :class="code" li to="/code">代码预览</routerLink>
            </li>
            <li>
              <routerLink :class="latex" li to="/latex">Latex预览</routerLink>
            </li>
            <li>
              <routerLink :class="black" li to="/blacktalk">互联网黑话</routerLink>
            </li>
            <li>
              <routerLink :class="qrcode" li to="/qrcode">二维码生成</routerLink>
            </li>
            <li>
              <routerLink :class="today" li to="/today">历史上的今天</routerLink>
            </li>
            <li>
              <routerLink :class="vip" li to="/vip">VIP视频</routerLink>
            </li>
            <li>
              <routerLink :class="game" li to="/game">小游戏</routerLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script setup>
// import { RouterLink } from 'vue-router';
// import { computed } from 'vue';
import { useRoute } from 'vue-router';
import {  ref, watch } from 'vue';
const route = useRoute()
const userData = ref()
let act = "nav-link active"
let unact = "nav-link"
let lisan = ref("nav-link")
let other = ref("nav-link")
let shumo = ref("nav-link")
let black = ref("nav-link")
let qrcode = ref("nav-link")
let today = ref("nav-link")
let vip = ref("nav-link")
let code = ref("nav-link")
let latex = ref("nav-link")
let game = ref("nav-link")
let navshou = ref(true);
function tr(now) {
  other.value = unact;
  lisan.value = unact;
  shumo.value = unact;
  black.value = unact;
  qrcode.value = unact;
  today.value = unact;
  vip.value = unact;
  code.value = unact;
  latex.value = unact;
  game.value = unact;
  now.value = act;
}
function dis() {
  if (userData.value === '/') {
    return true;
  } else if (userData.value === '/lisan') {
    return true;
  } else if (userData.value === '/shumo') {
    return true;
  } else if (userData.value === '/blacktalk') {
    return true;
  } else if (userData.value === '/qrcode') {
    return true;
  } else if (userData.value === '/today') {
    return true;
  } else if (userData.value === '/vip') {
    return true;
  } else if (userData.value.match(/\/code/)) {
    return true;
  } else if (userData.value === '/latex') {
    return true;
  } else if (userData.value === '/game') {
    return true;
  }
  return false;
}
watch(
  () => route.path,
   (newId) => {
     userData.value = newId,
    console.log(userData.value)
     if (userData.value === '/') {
      tr(other)
     } else if (userData.value === '/lisan') {
       tr(lisan)
     } else if (userData.value === '/shumo') {
       tr(shumo);
     } else if (userData.value === '/blacktalk') {
       tr(black);
     } else if (userData.value === '/qrcode') {
       tr(qrcode);
     } else if (userData.value === '/today') {
       tr(today);
     } else if (userData.value === '/vip') {
       tr(vip);
     } else if (userData.value.match(/\/code/)) {
      tr(code)
     } else if (userData.value === '/latex') {
      tr(latex)
     } else if (userData.value === '/game') {
       tr(game)
    
     }

     if (dis()) {
       console.log(1);
       navshou.value = true;
     } else {
       console.log(2);
       navshou.value = false;
     }
  }
)

</script>

<style>
</style>
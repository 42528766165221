<template>
<div class="out">
    <lay-row space="10">
         <lay-col md="12" sm="12" xs="24">
            <div class="inp1">
                <lay-textarea :rows="15" placeholder="请输入描述" :autosize="{minHeight: 100, maxHeight: 200}"  v-model="latextext"></lay-textarea>
                <div class="dis">
                    <lay-ripple type="out" trigger="always" color="white" borderRadius="2px" >
                        <lay-quote>
                            本页面可以对latex公式进行预览
                            <br>
                            请在上方的输入框中输入latex公式，在右边进行预览
                            <br>
                            latex公式请以"$"作为边界
                        </lay-quote>
                    </lay-ripple>
                </div>
            </div>
        </lay-col>
         <lay-col md="12" sm="12" xs="24">
            <div class="res">
            </div>
        </lay-col>
    </lay-row>
</div>
</template>

<script setup>
import { LayRow, LayCol, LayTextarea, LayQuote, LayRipple } from '@layui/layui-vue';
import { onMounted, ref, watch} from 'vue';
import $ from 'jquery';
import renderMathInElement from 'katex/contrib/auto-render/auto-render'
const renderOption = {
  delimiters: [
    {left: '$$', right: '$$', display: true},
    { left: '$', right: '$', display: true },
    { left: '$$$', right: '$$$', display: true },
    { left: '$$$$', right: '$$$$', display: true },
    
  ],
  throwOnError : true
}
let latextext = ref("")
onMounted(() => {
    console.log(document.body.clientHeight)
    // $(".inp1").css("height", document.body.clientHeight - 150 + 'px')
    $(".res").css("height", document.body.clientHeight - 150 + 'px')

    watch(latextext, () => {
        $(".res").html('<br><span>' + latextext.value + '</span>')
        renderMathInElement(document.querySelector(".res"), renderOption)
        // if (latextext.value.match(/\$.+?\$/gms)) {
        //     $(".inp1").css("padding-top", "15px");
        // } else {
        //     $(".inp1").css("padding-top", "0px");
        // }
    })
    // layer.notifiy({
    //   title:"bug通知",
    //   content:"页面有时会跳动，<s>我没办法解决<s>"
    // })
})

</script>

<style scoped>
.out{
    transform: translateY(50px);
    margin-left: 30px;
    margin-right: 30px;
    height: calc(100% - 100px);
}
.inp1{
    padding-top: 0px;
}
.res{
    height: 100%;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    font-family: consolas;
    font-weight: bold;
}
span{
    padding: 2px
}
.dis{
    margin-top: 40px;
    text-align: left;
}
.layui-quote{
    margin-bottom: 0px;
    background-color: #1e1e1e;
    color: #cccccc;

}
</style>
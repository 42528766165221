<template>
<table class="box" v-if="ok" >
    <div class="card float-start" style="width: 18rem;" v-for="(item, idx) in data" :key="idx">
        <!-- <img :src="item.picUrl" class="card-img-top"> -->
        <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ item.year }}年</p>
            <LayRipple  type="out" trigger="always" borderRadius="5px" color="#ffdd00">
                <button class="btn btn-primary" @click="detail(item.details)">查看详情</button>
            </LayRipple>
            
        </div>
    </div>
    <s3-layer
        v-model="det"
        title="详情"
        :skin="layui-layer-lan"
        :content="detail_text_"
        :type="0"
        :maxmin="true"
        :maxWidth="600"
        :shadeClose="true"
        :shade="0.5"
        >
    </s3-layer>
</table>

</template>
<script setup>
// import Axios from 'axios';
import $ from 'jquery'
import { ref } from 'vue';
import { LayRipple } from '@layui/layui-vue';
let data = []
let ok = ref(false)
let det = ref(false)
let detail_text_ = ref("")
// Axios({
//     mathod: 'get',
//     url: '\\api' + '/api/history/today',
//     params: {
//         'app_id': 'skrpjfqalfm8mukx',
//         'app_secret': 'ufdorV2xzuBHsvbgJv4QX97mSXWiBo6V',
//         'type': 1
//     }
// }).then(function (res) {
//     console.log(res.data.data);
//     data = res.data.data;
//     console.log(data)
//     ok.value = true;
// })

$.ajax({
    type: "GET",
    url: "https://www.mxnzp.com/api/history/today",
    dataType: 'json',
    data: {
        'app_id': 'skrpjfqalfm8mukx',
        'app_secret': 'ufdorV2xzuBHsvbgJv4QX97mSXWiBo6V',
        'type': 1
    },
    success: function (res) {
        console.log(res)
        data = res.data;
        console.log(data, 1)
        ok.value = true;
        setTimeout(() => {
            if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
                $(".card").css({
                    "position": "relative",
                    "left": "50%",
                    "transform": "translate(-50%)",
                    "margin-left": "0px"
                })
            }
        }, 100)
    }
})
function detail(detail_text) {
    detail_text_ = detail_text;
    det.value = true;
}


</script>
<style scoped>
.box{
    margin: 0 auto;
}
.card{
    margin-top: 40px;
    margin-left: 90px;
    color: #393D49;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    background-color: #9b7300;
}
.card-title{
    color: #393D49;
    font-weight: bold;
    height: 40px;
    text-shadow: 0 0 2px gray, 0 0 2px gray;
}
.card-text{
    color: #393D49;
}
.card-body{
    text-align: left;
    
}
.btn{
    background-color:#9b7300;
    color: #cccccc;
    border: 0px;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import hljs from 'highlight.js/lib/core';
import cpp from 'highlight.js/lib/languages/cpp';
import 'highlight.js/styles/vs2015.css'
import python from 'highlight.js/lib/languages/python';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import { s3Layer } from 'vue3-layer';
import 'vue3-layer/dist/s3Layer.css';
import Base64 from 'js-base64'
import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'
import katex from 'katex'
import 'katex/dist/katex.css'

hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('python', python);


createApp(App).use(Layui).use(katex).use(Base64).use(router).component('s3-layer', s3Layer).use(router).use(hljsVuePlugin).mount('#app')

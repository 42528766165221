<template>
<div class="box" v-if="load">
<select @click="se" class="form-select" aria-label="Default select example">
  <option v-for="item in data" :key="item.id" :value="item.id">接口{{ item.id }}</option>
</select>
<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">请输入视频地址</span>
  <input v-model="input" type="text" class="form-control" placeholder="请输入视频地址" aria-label="Username" aria-describedby="basic-addon1">
  <span @click="play" class="input-group-text" id="basic-addon1">确定</span>
</div>
<iframe security="restricted" sandbox="allow-scripts allow-same-origin allow-popups" :src="src" frameborder="0" class="myifr" allowfullscreen="true"></iframe>
</div>
</template>

<script setup>
import { ref } from 'vue';
import $ from 'jquery'
let load = ref(false);
let data = [];
let url = ref("");
let input = ref("")
let src = ref("")
console.log(load)
$.ajax({
    type: 'GET',
    url: '/vip.json',
    dataType: "json",
    success: function (resp) {
        console.log(resp)
        data = resp;
        load.value = true;
        url.value = data[0].url
    }
    
})
function se() {
    console.log($(".form-select")[0].value)
    play();
}
function play() {
    let i = $(".form-select")[0].value;
    url.value = data[i - 1].url;
    src.value = url.value + input.value
}
</script>


<style scoped>
.box {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 100px;
}
.form-select {
    width: 40%;
    max-width: 200px;

}
.myifr {
    width: 100%;
    max-width: 780px;
    position: relative;
    top: 50px;
    height: 60vw;
    max-height: 400px;
}
.input-group{
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
}
</style>
<template>
<div class="out">
    <div class="head">
        <div class="dis">
            2048
        </div>
        <div class="btn1">
            <LayButton @click="newgame" class=".btn1" type="primary">新游戏</LayButton>
        </div>
    </div>
    
    <LayRow space="1">
        <LayCol xs="2" sm="2" md="8" lg="8">
        </LayCol><LayCol xs="20" sm="20" md="8" lg="8">
            <div class="in">
                <div class="gameo">
                    <div class="game" id="0"></div>
                    <div class="game" id="1"></div>
                    <div class="game" id="2"></div>
                    <div class="game" id="3"></div>
                </div>
                <div class="gameo">
                    <div class="game" id="4"></div>
                    <div class="game" id="5"></div>
                    <div class="game" id="6"></div>
                    <div class="game" id="7"></div>
                </div>
                <div class="gameo">
                    <div class="game" id="8"></div>
                    <div class="game" id="9"></div>
                    <div class="game" id="10"></div>
                    <div class="game" id="11"></div>
                </div>
                <div class="gameo">
                    <div class="game" id="12"></div>
                    <div class="game" id="13"></div>
                    <div class="game" id="14"></div>
                    <div class="game" id="15"></div>
                </div>
            </div>
        </LayCol>
        <LayCol xs="2" sm="2" md="8" lg="8">
        </LayCol>
    </LayRow>    
</div>
</template>

<script setup>
import { LayRow, LayCol } from '@layui/layui-vue';
import { onMounted, watch } from 'vue';
import { layer, LayButton} from '@layui/layui-vue';
import $ from 'jquery'
let isold = false;
let gamearr = new Array(4)

for (let i = 0; i < 4; i++) {
    gamearr[i] = new Array(4)
    for (let j = 0; j < 4; j++) {
        gamearr[i][j] = 0;
    }
}
if (localStorage.getItem("gamearr") != null) {
    let gamearr_string = String(localStorage.getItem("gamearr"))
    gamearr_string = gamearr_string.split(',')
    for (let i = 0; i < 16; i++) {
        gamearr[parseInt(i / 4)][i % 4] = parseInt(gamearr_string[i])
    }
    isold = true;
}
let sta = true;
setInterval(() => {
    sta = true
}, 300)
onMounted(() => {
    document.onselectstart = function () { return false }
    document.oncontextmenu = function () { return false }
    document.ondragstart = function () { return false }
    document.onselect = function () { return false }
    console.log(gamearr)
    gameplay()
    $(".in").css("height", $(".in").css("width"))
    $(".game").css('line-height', parseInt($(".game").css('height')) - 12 + 'px')
    watch(() => document.querySelector(".in").style.height
    , () => {
        $(".in").css("height", $(".in").css("width"))
        }, { deep: true })
    gamef()
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        $(".game").css("font-size", "20px")
    }
})
function gamef() {
    gamestart()
}
function gamestart() {
    if (!isold) {
        let a = parseInt(Math.random() * 100) % 16;
        let b = parseInt(Math.random() * 100) % 16;
        if (a == b) {
            b = parseInt(Math.random() * 100) % 16;
        }
        console.log(parseInt(a / 4), a % 4)
        gamearr[parseInt(a / 4)][a % 4] = 2;
        gamearr[parseInt(b / 4)][b % 4] = 2;
    } else {
        isold = false;
    }
    fresh()
}
function newgame() {
    for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 4; j++) {
            gamearr[i][j] = 0;
        }
    }
    gamef()
}
function check() {
    let maxnum = 0;
    for (let i = 0; i < 16; i++) {
        if (gamearr[parseInt(i / 4)][i % 4] != 0) {
            let num = gamearr[parseInt(i / 4)][i % 4]
            if (num > maxnum) {
                maxnum = num;
            }
        }
    }
    if (maxnum === 2048) {
        layer.msg("你赢了", { icon: 6, time: 5000 })
        // for (let i = 0; i < 4; i++) {
        //     for (let j = 0; j < 4; j++) {
        //         gamearr[i][j] = 0;
        //         console.log(gamearr)
        //     }
        // }
        // console.log(gamearr, 2)
        // setTimeout(() => {
        //     gamef();
        // }, 5000)
    }
}
function fresh() {
    for (let i = 0; i < 16; i++) {
        if (gamearr[parseInt(i / 4)][i % 4] != 0) {
            let num = gamearr[parseInt(i / 4)][i % 4]
            $("#" + i).text(num)
            let bgc = ""
            let c = "";
            if (num == 2) {
                bgc = "#ecded5"
            } else if (num == 4) {
                bgc = '#e9dbc2'
            } else if (num == 8) {
                bgc = '#f0a76d'
            } else if (num == 16) {
                bgc = '#f38959'
            } else if (num == 32) {
                bgc = '#f46f53'
            } else if (num == 64) {
                bgc = '#f45234'
            } else if (num == 128) {
                bgc = '#eac667';
            } else if (num == 256) {
                bgc = '#eac357';
            } else if (num == 512) {
                bgc = '#eac047';
            } else if (num == 1024) {
                bgc = '#eabc39';
            } else if (num == 2048) {
                bgc = '#ecb928';
            }
            if (num <= 4) {
                c = "#6d615a"
            } else {
                c = 'white'
            }
            $("#" + i).css({
                'background-color': bgc,
                'color': c,
            })
        } else {
            $("#" + i).text("").css("background-color", "#cdc1b4")
        }
    }
    localStorage.setItem("gamearr", gamearr)
}
function gameplay() {
    let out = document.querySelector(".out")
    let start, end;
    out.addEventListener("mousedown", function (e) {
        start = [e.pageX, e.pageY]
    })
    out.addEventListener("mouseup", function (e) {
        end = [e.pageX, e.pageY]
        let dx = end[0] - start[0];
        let dy = end[1] - start[1]
        move(dx, dy);
    })

    out.addEventListener("touchstart", function (e) {
        start = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    })
    out.addEventListener("touchmove", function (e) {
        e.preventDefault()
    })
    out.addEventListener("touchend", function (e) {
        console.log(e.changedTouches[0].pageX)
        end = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
        let dx = end[0] - start[0];
        let dy = end[1] - start[1]
        move(dx, dy);
    })

    document.addEventListener("keydown", function (e) {
        if (sta) {
            if (e.key === 'ArrowDown') {
                down();
            } else if (e.key === 'ArrowUp') {
                up();
            } else if (e.key === 'ArrowLeft') {
                left();
            } else if (e.key === 'ArrowRight') {
                right();
            }
            sta = false;
        } 
    })
}
function move(dx, dy) {
    if (Math.abs(dy) > Math.abs(dx) && Math.abs(dy) > 5) {
        if (dy > 0) { 
            down();
        } else {
            up()
        }
    } else if (Math.abs(dy) < Math.abs(dx) && Math.abs(dx) > 5) {
        if (dx <= 0) {
            left();
        } else {
            right();
        }
    }
}
function newblock() {
    let res = []
    for (let i = 0; i < 16; i++) {
        if (gamearr[parseInt(i / 4)][i % 4] === 0) {
            res.push(i);
        }
    }
    if (res.length === 0) {
        layer.msg("你输了", { icon: 5, time: 1000 })
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
                gamearr[i][j] = 0;
            }
        }
        setTimeout(gamef, 1000)
    } else {
        let a = parseInt(Math.random() * 100) % res.length;
        gamearr[parseInt(res[a] / 4)][res[a] % 4] = 2;
        fresh();
    }
    
}
function up() {
    let cnt = 0;
    for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 4; j++) {
            if (j - 1 >= 0) {
                if (gamearr[i][j] != 0 && gamearr[i][j - 1] == 0) {
                    gamearr[i][j - 1] = gamearr[i][j];
                    gamearr[i][j] = 0;
                    cnt++;
                } else if (gamearr[i][j] != 0 && gamearr[i][j - 1] != 0 && gamearr[i][j] == gamearr[i][j - 1]) {
                    gamearr[i][j - 1] *= 2;
                    gamearr[i][j] = 0;
                    cnt++;
                }
            }
        }
    }
    fresh()
    if (cnt != 0) {
        setTimeout(up, 70)
    } else {
        newblock()
        check()
    }
}
function down() {
    let cnt = 0;
    for (let i = 0; i < 4; i++) {
        for (let j = 3; j >= 0; j--) {
            if (j + 1 <= 3) {
                if (gamearr[i][j] != 0 && gamearr[i][j + 1] == 0) {
                    gamearr[i][j + 1] = gamearr[i][j];
                    gamearr[i][j] = 0;
                    cnt++;
                } else if (gamearr[i][j] != 0 && gamearr[i][j + 1] != 0 && gamearr[i][j] == gamearr[i][j + 1]) {
                    gamearr[i][j + 1] *= 2;
                    gamearr[i][j] = 0;
                    cnt++;
                }
            }
        }
    }
    fresh()

    if (cnt != 0) {
        setTimeout(down, 70)
    } else {
        newblock()
        check()
    }
}
function left() {
    let cnt = 0;
    for (let i = 0; i < 4; i++) {
        for (let j = 0; j <= 3; j++) {
            if (j - 1 >= 0) {
                if (gamearr[j][i] != 0 && gamearr[j - 1][i] == 0) {
                    gamearr[j - 1][i] = gamearr[j][i];
                    gamearr[j][i] = 0;
                    cnt++;
                } else if (gamearr[j][i] != 0 && gamearr[j - 1][i] != 0 && gamearr[j][i] == gamearr[j - 1][i]) {
                    gamearr[j - 1][i] *= 2;
                    gamearr[j][i] = 0;
                    cnt++;
                }
            }
        }
    }
    fresh()
    if (cnt != 0) {
        setTimeout(left, 70)
    } else {
        newblock()
        check()
    }
}
function right() {
    let cnt = 0;
    for (let i = 0; i < 4; i++) {
        for (let j = 3; j >= 0; j--) {
            if (j + 1 <= 3) {
                if (gamearr[j][i] != 0 && gamearr[j + 1][i] == 0) {
                    gamearr[j + 1][i] = gamearr[j][i];
                    gamearr[j][i] = 0;
                    cnt++;
                } else if (gamearr[j][i] != 0 && gamearr[j + 1][i] != 0 && gamearr[j][i] == gamearr[j + 1][i]) {
                    gamearr[j + 1][i] *= 2;
                    gamearr[j][i] = 0;
                    cnt++;
                }
            }
        }
    }
    fresh()
    if (cnt != 0) {
        setTimeout(right, 70)
    } else {
        newblock()
        check()
    }
}
</script>

<style scoped>
.out{
    transform: translateY(60px);
}
.in{
    width: 100%;
    background-color: bisque;
    margin-left: 50%;
    transform: translateX(-50%);
    border: rgb(187, 173, 160) solid 8px;
    border-radius: 5px;
    height: 100%;
}
.game{
    height: 25%;
    width: 100%;
    background-color: rgb(205, 193, 180);
    border: rgb(187, 173, 160) solid 8px;
    font-size: 50px;
    text-align: center;
    color: rgb(119, 110, 101);
    font-weight: bold;
    
}
.gameo{
    float: left;
    height: 100%;
    width: 25%;
}
.dis, .btn1{
    float: left;
}
.head {
    margin-left: 50%;
}
.dis{
    color: #ffb800;
    font-size: 70px;
    margin-left: -70px
}
.btn1 {
    margin-left: 40px;
    margin-top: 30px;
    cursor: pointer !important;
}
* {
    cursor: move !important;
}
.layui-row {
    height: 100%;
}
</style>
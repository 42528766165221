<template>
<CodeDisplayPc v-if="PC" @sharef="(msg) => share(msg)" :codebase="route.params.base"/>
<CodeDisplayMobile v-if="M" @sharef="(msg) => share(msg)" :codebase="route.params.base"/>

</template>

<script setup>
import CodeDisplayPc from '@/components/CodeDisplayPc.vue';
import CodeDisplayMobile from '@/components/CodeDisplayMobile.vue'
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
const route = useRoute()
const router = useRouter()
let Base64 = require('js-base64').Base64
console.log(route)
function share(msg) {
    let str = Base64.encode(msg)
    str = str.replaceAll('/', '_')
    router.push('/code/' + str)
}
let PC = ref(true)
let M = ref(false)
if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    PC.value = false;
    M.value = true;
}
</script>

<style scoped>

</style>
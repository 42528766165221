<template>
    <div class="box">
    <highlightjs :language="route.params.lan" :code="code" />
    </div>
	<div class="switch">
		<!-- <lay-switch v-model="act" class="swi" onswitch-text="深色模式"  unswitch-text="浅色模式"></lay-switch> -->
	</div>
</template>
<script setup>
import { ref, watch } from "vue"
import $ from "jquery";
import { useRoute, useRouter } from "vue-router";
// import { LaySwitch } from "@layui/layui-vue";
import 'highlight.js/styles/vs2015.css'
let Base64 = require('js-base64').Base64
let act = ref(true)
const route = useRoute();
const router = useRouter();
console.log(route, router)
console.log(route, 3)
console.log(4)
let code = ref("请选择题目")
console.log(route.params.c)

$.ajax({
	type: "GET",
	url: "/"+ route.params.m + "/" + route.params.id + ".txt",
	dataType: "text",
	success: function (res) {
		code.value = res;
	}
});
if (route.params.base !== '' && route.params.base !== '0') {
	let str = ref(route.params.base)
	str.value = str.value.replaceAll('_', '/')
	code.value = Base64.decode(str.value)
}
watch(act, () => {
	if (act.value === true) {
		window.location.href = (location.origin + '/#/bigcode/' + route.params.lan + '/' + route.params.m + '/' + route.params.id + '/' + route.params.base);
	} else {
		if (route.params.base === '') {
			route.params.base = 0;
		}
		window.location.href = (location.origin + '/#/bigcodel/' + route.params.lan + '/' + route.params.m + '/' + route.params.id + '/' + route.params.base);
	}

})
</script>
<style scoped>
body{
    background: #1e1e1e;
}
.box{
    text-align: left;
}
.swi{
	position: fixed;
	right: 10px;
	top: 50px;
}
</style>
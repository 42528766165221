<template>
    <lay-notice-bar leftIcon="layui-icon-mute" rightIcon="layui-icon-close" :text="text" mode="closeable" background="#ecf5ff"	></lay-notice-bar>
</template>

<script>
import { ref } from "vue"

export default {
  setup() {

    const visible = ref(true);
        let text = ref("")
    text.value = "我的网站终于上线了，欢迎参观。有问题可以联系我反馈"
    return {
        visible,
        text
    }
  }
}
</script>
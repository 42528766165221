import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LiSan from '../views/LiSan.vue'
import BigCode from '../views/BigCode.vue'
import ShuMo from '../views/ShuMo.vue'
import BlackTalk from '@/views/BlackTalk.vue'
import QrCode from '@/views/QrCode.vue'
import HistoricallyToday from '@/views/HistoricallyToday.vue'
import VipVideo from '@/views/VipVideo.vue'
import CodeDisplay from '@/views/CodeDisplay.vue'
import NotFound from '@/views/NotFound.vue'
import BigCodel from '@/views/BigCodel.vue'
import LatexDisplay from '@/views/LatexDisplay.vue'
import Game2048View from '@/views/Game2048View'
import GamePage from '@/views/GamePage.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/lisan',
    name: 'lisan',
    component: LiSan,
  },
  {
    path: '/bigcode/:lan?/:m?/:id?/:base?',
    name: 'bigcode',
    component: BigCode,
  },
  {
    path: '/shumo',
    name: 'shumo',
    component: ShuMo
  },
  {
    path: '/blacktalk',
    name: 'blacktalk',
    component: BlackTalk
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: QrCode
  },
  {
    path: '/today',
    name: 'today',
    component: HistoricallyToday
  },
  {
    path: '/vip',
    name: 'vip',
    component: VipVideo
  },
  {
    path: '/code/:base?',
    name: 'code',
    component: CodeDisplay
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
  {
    path: '/bigcodel/:lan?/:m?/:id?/:base?',
    name: 'bigcodel',
    component: BigCodel
  },
  {
    path: '/latex',
    name: 'latex',
    component: LatexDisplay
  },
  {
    path: '/2048',
    name: '2048',
    component: Game2048View
  },
  {
    path: '/game',
    name: 'game',
    component: GamePage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
<div class="out">
    <div class="box">
    <lay-split-panel style="height: 95%">
        <lay-split-panel-item>
            <div class="code">
            <highlightjs class="coded" :code="code"/>
        </div> 
        </lay-split-panel-item>
        <lay-split-panel-item :space="600">
            <textarea placeholder="请输入代码" v-model="code" class="form-control" aria-label="With textarea"></textarea>
    </lay-split-panel-item>
    </lay-split-panel>
    </div>
<div class="btnbox">
    <button @click="share" type="button" class="btn  btn-secondary">分享</button>
    <button @click="full" type="button" class="btn  btn-secondary">全屏</button>
    <button @click="clear" type="button" class="btn  btn-secondary">清空</button>
</div>
</div>
</template>

<script setup>
import { ref } from 'vue';
import { defineEmits, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import $ from 'jquery'
import { onMounted } from 'vue';
import { LaySplitPanel, LaySplitPanelItem } from '@layui/layui-vue'
import { layer } from "@layui/layui-vue"
import 'highlight.js/styles/vs2015.css'
let Base64 = require('js-base64').Base64
let code = ref("")
const emit = defineEmits(['sharef'])
const router = useRouter();
const props = defineProps({
    codebase: String
})
if (props.codebase !== '') {
    let str = ref(props.codebase)
    str.value = str.value.replaceAll('_', '/')
    code.value = Base64.decode(str.value)
}
function share() {
    emit('sharef', code.value)
    console.log(code.value)
    
    if (location.href.match(/https/)) {
        setTimeout(() => {
        navigator.clipboard.writeText(location.href).then(
            () => {
                layer.msg("链接已复制到剪切板")
            }
        )
    }, 300)
    } else {
        layer.msg("请使用https协议")
    }
}
let noe_src = ref("");
function full() {
    emit('sharef', code.value)
    let str2 = Base64.encode(code.value)
    str2 = str2.replaceAll('/', '_')
    noe_src.value = location.origin + "/#/bigcode/0/0/0/";
    noe_src.value += str2
    console.log(str2, 3);
     layer.open({
        type: "iframe",
        title: "全屏代码",
        area: ['100%','100%'],
        content: noe_src.value
    })
    console.log(noe_src.value)
}
function clear() {
    router.push('/code')
    code.value = ""
}
    //textarea支持tab缩进
onMounted(() => {

  $("textarea").on(
            'keydown',
            function(e) {
                if (e.keyCode == 9) {
                    e.preventDefault();
                    var indent = '    ';
                    var start = this.selectionStart;
                    var end = this.selectionEnd;
                    var selected = window.getSelection().toString();
                    selected = indent + selected.replace(/\n/g, '\n' + indent);
                    this.value = this.value.substring(0, start) + selected
                            + this.value.substring(end);
                    this.setSelectionRange(start + indent.length, start
                            + selected.length);
                    code.value = code.value + ""
                   }
            })
})
</script>

<style scoped>
.out{
    height: 90%;
    width: 100%;
}
.left {
    height: 95%;
}

.right {
    height: 95%;
}
textarea {
    height: 100%;
}
textarea::placeholder{
    color: aliceblue;
}
.lay-split-panel-item{
		display: flex;
		font-weight: bold;
		justify-content: center;
		align-items: center;
}
.code {
    text-align: left;
    border-radius: 5px;
    height: 100%;
    max-width: 1000px;
}

.form-control{
    background-color: #1e1e1e;
    color: white;
    border: gray solid 2px;
}
.coded{
    border: gray solid 2px;
    height: 100%;
    border-radius: 5px;
    font-size: 15px;
}
.btnbox{
    position: fixed;
    right: 10px;
    top: 80px;
}
.btn {
    margin-left: 5px;
}
.box{
    margin-top: 50px;
    width: calc(100% - 100px);
    height: 97%;
    margin-left: 50px;
    margin-right: 50px;
}
</style>